import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import ApproveAssign from "pages/Preventive-maintenance/OutsideRoutes/ApproveAssign";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));

const AuthRegister = Loadable(
  lazy(() => import("pages/authentication/Register"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("pages/company-details/PrivacyPolicy"))
);

const TermsConditions = Loadable(
  lazy(() => import("pages/company-details/TermsAndConditions"))
);

const ContactUs = Loadable(
  lazy(() => import("pages/company-details/ContactUs"))
);

// ==============================|| AUTH ROUTING ||============================== //
const id =
  window.location.hash.replace("#/", "")?.split("/")?.length > 1 &&
  window.location.hash.replace("#/", "")?.split("/").pop();

const LoginRoutes = () => {
  return {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "/",
        element: <AuthLogin />,
      },
      {
        path: "/login",
        element: <AuthLogin />,
      },
      {
        path: "/register",
        element: <AuthRegister />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-conditions",
        element: <TermsConditions />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: id ? `/approve-assign/${id}` : `/approve-assign`,
        element: <ApproveAssign />,
      },
    ],
  };
};

export default LoginRoutes;
