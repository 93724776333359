import React, { useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  Background,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import "../../../assets/styles/flow.css";
import {
  InputLabel,
  Stack,
  Button,
  OutlinedInput,
  Tooltip,
  MenuItem,
  Grid,
} from "@mui/material";
import TextUpdaterNode from "./TextUpdaterNode";
import { useSelector, useDispatch } from "react-redux";
import {
  set_workflowData_list,
  set_workflowData_on_node,
} from "store/reducers/workflowData";
import { set_workflowNodes_list } from "store/reducers/workflowNodes";
import { set_workflowEdges_list } from "store/reducers/workflowEdges";

import MainCard from "components/MainCard";

const nodeTypes = { textUpdater: TextUpdaterNode };

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };


const initialNodes = [
  {
    id: "0",
    type: "textUpdater",
    data: { id: 0 },
    position: { x: 0, y: 50 },
  },
];

let id = 1;
const getId = () => `${id++}`;

const AddNodeOnEdgeDrop = () => {
  const original_data = useSelector((state) => state?.workflowData);
  const dispatch = useDispatch();

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();
  const onConnect = useCallback((params) => {
    // reset the start node on connections
    connectingNodeId.current = null;
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: {
            id: id,
          },
          origin: [0.5, 0.0],
          type: "textUpdater",
          dragHandle: false,
          selected: false,
        };

        dispatch(
          set_workflowData_on_node({
            id,
            source: connectingNodeId.current,
            target: id,
            instruction_id: "",
            role_id: "",
            employee_id: "",
          })
        );

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            id,
            source: connectingNodeId.current,
            target: id,
            type: "step",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 40,
              height: 40,
              color: "#784be8",
            },
          })
        );
      }
    },
    [screenToFlowPosition]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      let data_list = [...original_data];
      deleted.forEach((deletedItem) => {
        const index = data_list.findIndex((item) => {
          return item && parseInt(item.id) === parseInt(deletedItem.id);
        });
        if (index !== -1) {
          data_list[index] = null;
        }
      });

      dispatch(set_workflowData_list(data_list));
    },
    [nodes, edges]
  );

  useEffect(() => {
    dispatch(set_workflowNodes_list(nodes));
  }, [nodes]);

  useEffect(() => {
    dispatch(set_workflowEdges_list(edges));
  }, [edges]);

  return (
    <MainCard>
      <div
        className="wrapper"
        ref={reactFlowWrapper}
        style={{ height: "65vh" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onNodesDelete={onNodesDelete}
          fitView
          defaultViewport={defaultViewport}
          fitViewOptions={{ padding: 2 }}
          nodeOrigin={[0.5, 0]}
          nodeTypes={nodeTypes}
          style={{
            backgroundColor: "#E3F2FD",
          }}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
      <Stack direction="row" justifyContent="end" sx={{ m: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            console.log("Datalist ==>", original_data);
            console.log("Edges ==>", edges);
            console.log("Nodes ==>", nodes);
          }}
        >
          Submit
        </Button>
      </Stack>
    </MainCard>
  );
};

export default () => (
  <ReactFlowProvider>
    <AddNodeOnEdgeDrop />
  </ReactFlowProvider>
);

const Datalist = [
  null,
  {
    id: 1,
    instruction_id: "Approve",
    role_id: "Managers",
    employee_id: "John Doe",
  },
  {
    id: 2,
    instruction_id: "Reject",
    role_id: "Managers",
    employee_id: "John Doe",
  },
  {
    id: 3,
    instruction_id: "Approve & Assign",
    role_id: "Quality Manager",
    employee_id: "Jane Smith",
  },
  {
    id: 4,
    instruction_id: "Reject",
    role_id: "Quality Manager",
    employee_id: "Jane Smith",
  },
  {
    id: 5,
    instruction_id: "Return",
    role_id: "Quality Manager",
    employee_id: "Jane Smith",
  },
  {
    id: 6,
    instruction_id: "Return",
    role_id: "Managers",
    employee_id: "John Doe",
  },
];

const Edges = [
  {
    id: "1",
    source: "0",
    target: "1",
    type: "step",
  },
  {
    id: "2",
    source: "0",
    target: "2",
    type: "step",
  },
  {
    id: "3",
    source: "1",
    target: "3",
    type: "step",
  },
  {
    id: "4",
    source: "1",
    target: "4",
    type: "step",
  },
  {
    id: "5",
    source: "1",
    target: "5",
    type: "step",
  },
  {
    id: "6",
    source: "0",
    target: "6",
    type: "step",
  },
];

const Nodes = [
  {
    id: "0",
    type: "textUpdater",
    data: {
      label: "Initiate",
      value: 0,
    },
    position: {
      x: -33,
      y: -43,
    },
    width: 250,
    height: 137,
    selected: false,
    positionAbsolute: {
      x: -33,
      y: -43,
    },
    dragging: false,
  },
  {
    id: "1",
    position: {
      x: -432,
      y: 191.03125,
    },
    data: {
      id: "1",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: false,
    width: 250,
    height: 137,
  },
  {
    id: "2",
    position: {
      x: 203.87471385882088,
      y: 191.3256140690703,
    },
    data: {
      id: "2",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: true,
    width: 250,
    height: 137,
    positionAbsolute: {
      x: 203.87471385882088,
      y: 191.3256140690703,
    },
    dragging: false,
  },
  {
    id: "3",
    position: {
      x: -838.2319155408159,
      y: 403.5761863514285,
    },
    data: {
      id: "3",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: false,
    width: 250,
    height: 137,
  },
  {
    id: "4",
    position: {
      x: -334.19421297306104,
      y: 406.8705504204988,
    },
    data: {
      id: "4",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: false,
    width: 250,
    height: 137,
    positionAbsolute: {
      x: -334.19421297306104,
      y: 406.8705504204988,
    },
    dragging: false,
  },
  {
    id: "5",
    position: {
      x: 59.48229328083893,
      y: 405.2233683859636,
    },
    data: {
      id: "5",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: false,
    width: 250,
    height: 137,
  },
  {
    id: "6",
    position: {
      x: 678.822738266054,
      y: 186.1481577927891,
    },
    data: {
      id: "6",
    },
    origin: [0.5, 0],
    type: "textUpdater",
    dragHandle: false,
    selected: false,
    width: 250,
    height: 137,
    positionAbsolute: {
      x: 678.822738266054,
      y: 186.1481577927891,
    },
    dragging: false,
  },
];
